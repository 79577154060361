import React from "react";
import QuizPage from "./QuizPage";

function FiveQuestionQuiz() {

  return (
    <div>
      <QuizPage title="5 Compounds Quiz" count="5" />
    </div>
  );
}

export default FiveQuestionQuiz;
