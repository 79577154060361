import React from "react";

function SignificantFigures() {
  return (
    <div className="left-aligned">
      <h1>Significant Figures in Chemistry: Definition, Rules, and Practice Questions</h1>

      <section>
      <h2>Introduction</h2>

      In chemistry, precision and accuracy are critical. When performing calculations or recording measurements, it’s essential to communicate how exact those values are. This is where significant figures (or significant digits) come into play. Significant figures are the digits in a number that contribute to its precision, including all known digits and one estimated digit.

      </section>
<section>
<h2>What Are Significant Figures? </h2>

Significant Figures, also referred to as significant digits or sig figs, refer to the number of meaningful digits in a measurement or calculation. They indicate the precision of a value, which is particularly important in scientific experiments where results depend on accurate measurements.

For example, the number 3.57 has three significant digits, reflecting a higher precision than 3.5, which has only two.
</section>
<section>
<h2>Rules for Determining Significant Digits</h2>

To determine the number of significant digits in a number, follow these rules:<br/><br/>

1) Non-Zero Digits Are Always Significant<br/>
&nbsp;&nbsp;&nbsp;Example: 123 has three significant digits.<br/><br/>
2) Zeros Between Non-Zero Digits Are Significant<br/>
&nbsp;&nbsp;&nbsp;Example: 105 has three significant digits.<br/><br/>
3) Leading Zeros Are Not Significant<br/>
&nbsp;&nbsp;&nbsp;Example: 0.0045 has two significant digits.<br/><br/>
4) Trailing Zeros Are Significant If There’s a Decimal Point<br/>
&nbsp;&nbsp;&nbsp;Example: 100.0 has four significant digits, but 100 has only one.<br/><br/>
5) In Scientific Notation, All Digits in the Coefficient Are Significant<br/>
&nbsp;&nbsp;&nbsp;Example: 1.23 × 103 has three significant digits.

</section>
  <section>
<h2>Why Are Significant Digits Important in Chemistry?</h2>

In chemistry, calculations often involve measurements such as mass, volume, and concentration. These measurements have limitations due to the precision of instruments. Significant digits ensure that results are reported accurately, reflecting the reliability of the data without overstating its precision.

For example, if a balance measures mass to the nearest 0.01 g, reporting a measurement as 1.2345 g would falsely imply greater precision than the balance can provide.
</section>
<section>
<h2>Operations with Significant Digits</h2>

When performing calculations, the rules for significant digits vary depending on the operation:<br/>

<h3>Addition and Subtraction</h3>
The result should have the same number of decimal places as the measurement with the fewest decimal places.<br/>
Example: 12.11 + 0.4 = 12.5 (rounded to one decimal place).
<h3>Multiplication and Division</h3>
The result should have the same number of significant digits as the measurement with the fewest significant digits.<br/>
Example: 3.57×2.1=7.5 (rounded to two significant digits).
</section>

<section>

<h2>Practice Questions</h2>

Here are some practice problems to test your understanding of significant digits:<br/><br/>

Identify the number of significant digits in each of the following:<br/>
a) 0.00234<br/>
b) 4050<br/>
c) 3.010 × 104<br/><br/>

Perform the following calculations and express the answer with the correct number of significant digits:<br/>
a) 12.45+7.1 <br/>
b) 6.74×0.003 <br/>
c) 123.4/2.1 <br/><br/>

<h3>Answers<br/></h3>

Significant digits:<br/>
a) 3 (0.00234)<br/>
b) 3 (4050)<br/>
c) 4 (3.010×104)<br/><br/>

Calculations:<br/>
a) 12.45+7.1=19.6 (one decimal place)<br/>
b) 6.74×0.003=0.020 (two significant digits)<br/>
c) 123.4/2.1=59 (two significant digits)
</section>
<section>
<h2>Conclusion</h2>

Understanding and correctly using significant digits is a fundamental skill in chemistry that ensures the precision and accuracy of scientific data. By practicing the rules and applying them consistently, you can improve the reliability of your experimental results and calculations.
</section></div>
    );
}

export default SignificantFigures;