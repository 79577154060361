import React from "react";
import { Routes, Route, Link } from "react-router-dom";
import FiveQuestionQuiz from "./quizzes/FiveQuestionQuiz";
import TenQuestionQuiz from "./quizzes/TenQuestionQuiz";

function Quizzes() {
  return (
    <div className="learn-container">
      <h1>Do more quizzes</h1>
      <nav className="sub-navbar">
        <ul className="sub-nav-links">
          <li>
            <Link to="FiveQuestionQuiz">5-question Quiz</Link>
          </li>
          <li>
            <Link to="TenQuestionQuiz">10-question Quiz</Link>
          </li>
        </ul>
      </nav>
      <div className="sub-content">
        <Routes>
          <Route path="/quizzes/FiveQuestionQuiz" element={<FiveQuestionQuiz />} />
          <Route path="/quizzes/TenQuestionQuiz" element={<TenQuestionQuiz />} />
        </Routes>
      </div>
    </div>
  );
}

export default Quizzes;